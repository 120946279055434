<template>
  <div class="item-panel">
    <div style="
        color: #b1d9ff;
        font-size: 14px;
        font-weight: Bold;
        padding: 8px 0 8px 28px;
      " class="title" v-if="title">
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'item-panel',
  props: {
    title: String
  }

  //   setup(props) {
  //     const title = props.title
  //     return {
  //         title
  //     }
  //   },

  //   props: {
  //     title: {
  //       type: String,
  //       default() {
  //         return "";
  //       },
  //     },
  //   },
}
</script>
<style scoped>
  .item-panel {
    height: 100%;
    position: relative;
    /* background-image: url(/static/images/monitoring/item.png);
  background-repeat: no-repeat;
  background-size: 100% 100%; */
    border: 1px solid #0bc4e9;
    background: rgba(0, 72, 115, 0.28);
    transition: all 1s;
    border-radius: 10px;
  }

  .item-panel:hover::before {
    width: 0%;
  }

  .item-panel::before {
    content: '';
    position: absolute;
    width: 80%;
    height: 100%;
    bottom: -1px;
    top: -1px;
    left: 10%;
    border-bottom: 1px solid #007297;
    border-top: 1px solid #007297;
    transition: all 0.5s;
    box-sizing:content-box
  }

  .item-panel:hover:after {
    height: 0%;
  }

  .item-panel::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    left: -1px;
    right: -1px;
    top: 10%;
    border-left: 1px solid #007297;
    border-right: 1px solid #007297;
    transition: all 0.5s;
    box-sizing:content-box
  }

  .item-panel .title {
    background-image: url(/static/images/monitoring/item-title.png);
    background-position: bottom left;
    background-repeat: no-repeat;
  }
</style>
