<template>
  <div
    style="width: 20px;height: 20px;background: #01DFF4;border-radius: 3px;display: flex;align-items: center;justify-content: center;cursor: pointer" @mouseup="onMouseUp" @mousedown="onClick">
    <div style="width: 13px;height: 2px;background: #000;"></div>
    <div style="width: 2px;height: 13px;background: #000;position:absolute;" v-if="plus"></div>
  </div>
</template>
<script>
export default {
  props: {
    plus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.plus ? this.$emit('plus') : this.$emit('minus')
    },

    onMouseUp () {
      this.$emit('mouseup')
    }
  }
}
</script>
