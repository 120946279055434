<template>
  <div class="monitoring-container">
    <div class="container-fluid"
         style="height: 60px;line-height: 60px;font-size:26px;font-weight:bold;color:#FFFFFF;padding: 0 20px">
      <span>{{ userInfo.organizationName }}-监控中心</span>
    </div>
    <div class="container-fluid" style="padding: 20px">
      <div class="tbc-flex-row" style="display: flex;flex-direction: row">
        <div class="tbc-flex-col" style="display: flex;flex-direction: column">
          <div style="width: 290px;height:502px;position: relative">
            <div style="position:absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 9" @click="$message.error('当前处于轮询模式，请停止轮询')" v-if="inPolling"></div>
            <item-panel>
              <div style="height:458px;overflow: hidden auto;margin-right: 8px;margin-top: 20px;margin-left: 20px">
<!--                <label style="color: #FFF;margin-right: 10px;font-size: 12px">筛选</label>-->
<!--                <select-->
<!--                  style="width:210px;height:28px;background:#041648;border:1px solid #0A529B;border-radius:4px;color:#FFF;padding-left: 6px">-->
<!--                  <option v-for="(item, index) in typeList" :key="index"-->
<!--                          :value="index + 1">-->
<!--                    {{ item.label }}-->
<!--                  </option>-->
<!--                </select>-->

                <div style="margin-top: 20px;margin-bottom: 26px;padding-left: 20px">
                  <el-radio-group v-model="mode" class="mode" @change="onModeChange">
                    <el-radio label="custom" :disabled="inPolling">自定义模式</el-radio>
                    <el-radio label="polling">轮询模式</el-radio>
                  </el-radio-group>
                </div>

                <div class="video-tree">
                  <el-tree ref="tree" :props="props" node-key="value" :data="treeData"
                           lazy :show-checkbox="bShowTreeCheckBox" :load="loadNode" @node-click="onTreeNodeClick"
                           @check="onTreeNodeCheck">
                  </el-tree>
                </div>
              </div>

            </item-panel>
          </div>

          <div style="width: 290px;height:320px;margin-top: 20px">
            <item-panel>
              <div style="height:320px">
                <div style="margin-top: 10px">
                  <steering-wheel @click="sendCommand" @mouseup="sendStopCommand"></steering-wheel>
                </div>

                <p
                  style="font-size:14px;font-family:Microsoft YaHei;font-weight:400;color:#FFFFFF;line-height:26px;padding-left: 74px;">
                  横向运动速度：3m/s</p>
                <p
                  style="font-size:14px;font-family:Microsoft YaHei;font-weight:400;color:#FFFFFF;line-height:26px;padding-left: 74px;">
                  竖向运动速度：3m/s</p>
                <div
                  style="font-size:14px;font-family:Microsoft YaHei;font-weight:400;color:#FFFFFF;line-height:26px;display: flex;flex-direction: row;padding-left: 74px;align-items: center;margin-top: 10px">
                  变倍：
                  <plus-minus plus @plus="sendCommand('enlarge')" @mouseup="sendStopCommand"></plus-minus>
                  <div style="margin-left: 20px">
                    <plus-minus @minus="sendCommand('reduce')" @mouseup="sendStopCommand"></plus-minus>
                  </div>
                </div>
                <div
                  style="font-size:14px;font-family:Microsoft YaHei;font-weight:400;color:#FFFFFF;line-height:26px;display: flex;flex-direction: row;padding-left: 74px;align-items: center;margin-top: 10px">
                  聚焦：
                  <plus-minus plus @plus="sendCommand('far')" @mouseup="sendStopCommand"></plus-minus>
                  <div style="margin-left: 20px">
                    <plus-minus @minus="sendCommand('near')" @mouseup="sendStopCommand"></plus-minus>
                  </div>
                </div>
              </div>
            </item-panel>
          </div>
        </div>
        <div style="flex: 1;margin-left: 20px;">
          <div style="display: flex;flex-direction: row;margin-bottom: 10px" v-if="mode === 'polling'">
            <div class="operation" @click="startPolling">开始轮询</div>
            <div class="operation" @click="pausePolling">暂停轮询</div>
            <div class="operation" @click="stopPolling">停止轮询</div>
            <div class="operation" @click="prev">上一页</div>
            <div class="operation" @click="next">下一页</div>
          </div>
          <div style="flex-wrap: wrap;display: flex;flex-direction: row">
            <div v-for="(item) in playList" :key="item.deviceId" @click="selectVideo(item)"
                 style="width: 48%;height:421px;flex-shrink: 0;border: 1px solid #106EBA;margin: 2px"
                 :style="item.deviceId === selectedDeviceId ? 'border: 1px solid #00E9FF':''">
              <player :info="item" :loading="item.loading" :url="item.url" :text="item.text" :title="item.title" @error="onPlayerError" ></player>
            </div>

            <div v-for="(index) in maxPlay - playList.length" :key="index"
                 style="width: 48%;height:421px;flex-shrink: 0;border: 1px solid #106EBA;margin: 2px">
              <player :loading="false" text="暂无设备"></player>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ItemPanel from './components/ItemPanel'
import Player from './components/Player'
import PlusMinus from './components/PlusMinus'
import SteeringWheel from './components/SteeringWheel'
import cookie from '@/util/cookie'
import { mapState } from 'vuex'
import { socketUrl } from '../../config/config'
import * as api from '../../api/monitorCenterVideo'
export default {

  name: 'monitor-video',

  components: {
    ItemPanel,
    Player,
    PlusMinus,
    SteeringWheel
  },

  data () {
    return {
      props: {
        label: 'label',
        children: 'children',
        isLeaf: 'isLeaf'
      },
      treeData: [],
      treeLazyMapper: {},
      mode: 'custom',
      socket: null,
      playList: [],
      selectedDeviceId: '',
      commandMapper: {
        left: 1284,
        right: 1282,
        up: 1026,
        down: 1028,
        reduce: 770,
        enlarge: 772,
        far: 516,
        near: 514
      },
      maxPlay: 4,
      lastCommand: '',
      pollingTimer: null,
      pollingPage: 1,
      pollingList: [],
      inPolling: false,
      typeList: [
        { value: '', label: '全部' },
        { value: 11, label: '宿舍' },
        { value: 1, label: '为楼宇' },
        { value: 2, label: '空地' },
        { value: 3, label: '大门' }
      ],
      pullStreamTimeout: 10,
      pullStreamTimeoutDetecting: false,
      clientId: this.$tools.uuid()
    }
  },

  computed: {
    // clientId () {
    //   let userId = this.$store.state.user.countyUserInfo.id
    //   if (!userId) {
    //     let storage = window.localStorage.getItem('countyUserInfo')
    //     if (storage) storage = JSON.parse(storage)
    //     userId = storage.id
    //   }
    //   return userId
    // },
    ...mapState({
      userInfo (state) {
        return state.user.countyUserInfo
        // let countyUserInfo = { organizationIdStr: '' }
        // const info = localStorage.getItem('countyUserInfo')
        // if (info) countyUserInfo = JSON.parse(info)
        // return countyUserInfo
      }
    }),

    eduId () {
      return this.userInfo.organizationIdStr
    },

    bShowTreeCheckBox () {
      return this.mode === 'polling'
    },

    maxPollingPage () {
      return Math.ceil(this.pollingList.length / this.maxPlay)
    }
  },

  created () {
    this.connect()
    // for (let i = 0; i < 18; i++) {
    //   this.pollingList.push({
    //     title: '视频播放' + (i + 1),
    //     label: '视频播放' + (i + 1),
    //     deviceId: i,
    //     loading: false,
    //     text: '视频加载中'
    //   })
    // }
    this.buildWindowData()
  },

  methods: {
    onPlayerError (item, message, code) {
      item.text = message
      item.loading = false
      if (code === 404) item.url = ''
    },

    connect () {
      // const uuid = this.$tools.uuid()
      //
      // this.socket.uuid = uuid
      if (this.$route.query._dev) {
        this.socket = new WebSocket('ws://192.168.66.41:9978/ws?clientId=' + this.clientId)
      } else {
        this.socket = new WebSocket(socketUrl + '/platform-edge-device?clientId=' + this.clientId)
      }
      // this.socket = new WebSocket('wss://t-api.xueerqin.net/ws?clientId=' + this.clientId)
      this.socket.onopen = () => {
        console.log('open')
      }

      this.socket.onmessage = (e) => {
        const data = JSON.parse(e.data)
        console.log(data)
        // console.log(this.playList, 'this.playList')
        const item = this.playList.find(item => item.deviceId === data.deviceId)
        if (item !== undefined) {
          if (data.status === 200) {
            item.loading = false
            item.url = data.flvUrl
          } else {
            item.text = data.errorText
          }
        }
      }
      this.socket.onclose = (e) => {
        console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
        console.log('close')
        this.connect()
      }
      this.socket.onerror = function (error) {
        console.log(error)
      }
    },
    // 生成窗口数据
    buildWindowData () {
      const max = this.maxPlay - this.playList.length
      for (let i = 0; i < max; i++) {
        this.playList.push({
          deviceId: i,
          label: '',
          loading: false,
          text: '暂无设备',
          sendPullStreamTime: new Date().getTime()
        })
      }
    },

    // 请求推流监控
    startPullStreamTimeoutDetect () {
      if (this.pullStreamTimeoutDetecting) return
      this.pullStreamTimeoutDetecting = true
      setTimeout(() => {
        const current = new Date().getTime()
        console.log(current)
        let bHasRequest = false
        this.playList.forEach(item => {
          if (item.loading) {
            bHasRequest = true
            if (current - item.sendPullStreamTime > 10000) {
              item.loading = false
              item.text = '请求超时，请重试'
            }
          }
        })
        this.pullStreamTimeoutDetecting = false
        if (bHasRequest) this.startPullStreamTimeoutDetect()
      }, 1000)
    },

    onModeChange (e) {
      this.mode = e
    },

    // 选中窗口
    selectVideo (data) {
      this.selectedDeviceId = data.deviceId
    },

    // 发送命令
    sendCommand (command) {
      this.sendControlCommand(this.commandMapper[command], true)
    },

    // 发送停止命令
    sendStopCommand () {
      this.sendControlCommand(this.lastCommand - 1, false)
    },

    // 控制指令
    async sendControlCommand (command) {
      if (this.bShowTreeCheckBox) return this.$message.error('当前处于轮询模式，请先停止')
      if (!this.playList.length) return this.$message.error('没有监控可操作')
      const data = this.playList.find(item => item.deviceId === this.selectedDeviceId)
      if (data === undefined) return this.$message.error('请选择操作的监控')
      const res = await this.http.get('/platform-edge-device/cameraController/cameraControl', {
        clientId: this.clientId,
        command,
        commandPara1: '',
        commandPara2: '',
        deviceId: data.deviceId,
        schoolId: data.schoolId
      })
      this.lastCommand = command
      if (res.status !== 200) this.$message.error('操作失败')
    },

    onTreeNodeClick (e) {
      if (e.type === 1 && !this.bShowTreeCheckBox) {
        this.getPullStream(e)
      }
    },

    onTreeNodeCheck (a, checkedList) {
      // this.pollingList = []
      console.log(checkedList)
      checkedList.checkedNodes.forEach(item => {
        if (item.type === 1) {
          item.loading = true
          this.pollingList.push(item)
        }
      })
    },

    // 开始轮询
    startPolling () {
      if (!this.pollingList.length) return this.$message.error('请选择播放的监控')
      this.pausePolling()
      this.pollingHandle()
      this.inPolling = true
      if (this.pollingList.length <= this.maxPlay) return
      this.pollingTimer = setInterval(() => {
        this.pollingHandle()
        console.log('pollingHandle')
      }, 30000)
    },

    // 暂停轮询
    pausePolling () {
      clearInterval(this.pollingTimer)
    },

    // 停止轮询
    stopPolling () {
      clearInterval(this.pollingTimer)
      this.inPolling = false
    },

    prev () {
      console.log(this.pollingPage)
      // if (this.pollingPage <= 2) return
      this.pausePolling()
      if (this.pollingPage > 2) {
        this.pollingPage -= 2
      } else {
        this.pollingPage = this.maxPollingPage
      }
      this.startPolling()
    },

    next () {
      console.log(this.maxPollingPage)
      console.log(this.pollingPage)
      // if (this.pollingPage > this.maxPollingPage) return
      console.log('next')
      this.pausePolling()
      // this.pollingPage++
      this.startPolling()
    },

    pollingHandle () {
      // this.playList = []
      let count = 0
      const start = (this.pollingPage - 1) * this.maxPlay
      for (let i = start; i < this.pollingList.length; i++) {
        if (count++ < this.maxPlay) {
          console.log('111')
          this.getPullStream(this.pollingList[i])
          // this.playList.push(this.pollingList[i])
        }
      }
      this.pollingPage++
      if (start + this.maxPlay >= this.pollingList.length) this.pollingPage = 1 // this.maxPollingPage
    },

    // 发起推流
    async getPullStream (data) {
      if (this.socket === null || this.socket.readyState !== 1) {
        return this.$message.error('服务器连接中，请稍后重试...')
      }
      const item = {
        title: data.label,
        deviceId: data.deviceId,
        schoolId: data.schoolId,
        clientId: this.clientId,
        loading: true,
        devFlag: true,
        text: '正在连接设备',
        sendPullStreamTime: (new Date()).getTime()
      }
      let index = this.playList.findIndex(item => item.deviceId === data.deviceId)
      if (index < 0) index = this.playList.findIndex(item => item.deviceId === this.selectedDeviceId)
      if (index < 0) index = this.playList.findIndex(item => item.label === '')
      this.playList.splice(index, 1, item)
      // this.selectedDeviceId = data.deviceId
      this.socket.send(JSON.stringify({
        deviceId: data.deviceId,
        schoolId: data.schoolId,
        clientId: this.clientId,
        devFlag: true
      }))
      this.startPullStreamTimeoutDetect()
    },

    async loadNode (node, resolve) {
      console.log(node)
      if (node.level === 0) {
        const res = await this.getSchoolList()
        resolve(res)
      } else if (node.level === 1) {
        const res = await this.getDeviceList(node.data.value)
        console.log(res)
        resolve(res)
      } else {
        resolve(this.treeLazyMapper[node.data.value])
      }
    },

    // 学校列表
    async getSchoolList () {
      const res = await api.getSchoolList({
        eduId: this.eduId // '39fbfa0ea86dc7dbde0f51cc49d680ee'
      })
      const treeData = []
      if (!res.data) res.data = []
      res.data.forEach(item => {
        treeData.push({
          label: item.schoolName,
          value: item.schoolId,
          isLeaf: false,
          children: []
        })
      })
      return treeData
    },
    async getDeviceList (schoolId) {
      const res = await api.getDeviceList({
        schoolId
      })
      if (!res.data) res.data = []
      const deviceList = []
      res.data.forEach(item => {
        deviceList.push({
          label: item.deviceName,
          value: item.deviceId,
          type: 1,
          isLeaf: true,
          schoolId: item.schoolId,
          deviceId: item.deviceId
        })
      })
      return deviceList
    },

    // 获取预置位信息
    async getPresetPositions (data) {
      const res = await this.http.get('/platform-edge-device/command-centerapi/getPresetPositions', {
        deviceId: data.deviceId
      })
      console.log(res)
      if (res.status !== 0) return ''
      return res.data.monitorPresetPositionId || ''
    },

    async getBuildings (schoolId) {
      const res = await this.http.get('/platform-edge-device/command-centerapi/getBuildings', {
        schoolId
      })
      if (res.status !== 200) return []
      return this.recursionFormatToTreeData(res.data)
    },

    // 递归处理成tree数据格式
    recursionFormatToTreeData (data) {
      const treeData = []
      data.forEach(item => {
        treeData.push({
          label: item.buildingName,
          value: item.buildingId,
          children: [],
          isLeaf: true
        })
        this.treeLazyMapper[item.buildingId] = this.formatFloorToTreeData(item.childrenBuildingVo || [])
      })
      return treeData
    },

    //
    formatFloorToTreeData (data) {
      const treeData = []
      data.forEach(item => {
        treeData.push({
          label: item.detailName,
          value: item.detailId,
          children: [],
          isLeaf: !item.childrenBuildingVO
        })
        this.treeLazyMapper[item.detailId] = this.formatFloorToTreeData(item.childrenBuildingVO || [])
      })
      return treeData
    },

    // 获取
    async getDeviceInfo (detailId) {
      const res = this.http.get('/platform-edge-device/command-centerapi/getSchoolDevices', {
        detailId
      })
    }
  }
}
</script>
<style>
.monitoring-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../assets/img/body.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.el-tree label {
  margin-bottom: 0
}
.el-tree-node__expand-icon.is-leaf{
  visibility: hidden;
}
.video-tree .el-tree {
  background: transparent;
  color: #FFFFFF;
}

.video-tree .el-tree .el-tree-node__content:hover, .video-tree .el-tree .el-upload-list__item:hover {
  background-color: transparent;
}

.video-tree .el-tree-node__expand-icon {
  color: #4083BF;
}

.video-tree .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: transparent;
}

.video-tree .el-tree-node:focus > .el-tree-node__content {
  background-color: transparent;
}

.mode .el-radio {
  color: #FFF;
}

.operation {
  width: 62px;
  height: 22px;
  line-height: 22px;
  background: #136DBB;
  border-radius: 3px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin: 0 6px;
  cursor: pointer;
  text-align: center;
}

select:focus-visible {
  outline: 0;
}
</style>
