import axios from './axios'
import { apiUrl } from '@/config/config'
const apiPrefix = apiUrl + '/platform-edge-device/command-centerapi'

// 学校列表
export const getSchoolList = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: apiPrefix + '/getSchools',
      method: 'get',
      params
    }).then(res => {
      resolve(res)
    })
  })
}

// 学校设备
export const getDeviceList = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      url: apiPrefix + '/getSchoolDevices',
      method: 'get',
      params
    }).then(res => {
      resolve(res)
    })
  })
}
