<template>
  <div
    style="width: 157px;height: 157px;margin: 0px auto;position: relative;display: flex;flex-direction: column;align-items: center;justify-content: center">
    <div style="width:65px;height: 65px;">
      <img src="../../../assets/img/video/wheel-center.png" style="width: 100%;height: 100%"/>
    </div>
    <!-- 上 -->
    <div class="wheel-button" style="left: 50%;margin-left: -49px;top: 0;" :class="{'hold': direct === 'up'}">
      <div class="click-area"
           @mousedown="onSteeringWheelClick('up')" @mouseup="onMouseUp"></div>
    </div>

    <!-- 下 -->
    <div class="wheel-button rotate-180"
         style="left: 50%;margin-left: -49px;bottom: 0;" :class="{'hold': direct === 'down'}">
      <div class="click-area"
           @mousedown="onSteeringWheelClick('down')" @mouseup="onMouseUp"></div>
    </div>

    <!-- 左 -->
    <div class="wheel-button rotate-270"
         style="left: 0;margin-left: -26px;top: 50%;margin-top: -26px" :class="{'hold': direct === 'left'}">
      <div class="click-area"
           @mousedown="onSteeringWheelClick('left')" @mouseup="onMouseUp"></div>
    </div>

    <!-- 右 -->
    <div class="wheel-button rotate-90"
         style="right: 0;margin-right: -26px;top: 50%;margin-top: -26px" :class="{'hold': direct === 'right'}">
      <div class="click-area"
           @mousedown="onSteeringWheelClick('right')" @mouseup="onMouseUp"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      direct: ''
    }
  },

  methods: {
    onSteeringWheelClick (direct) {
      this.direct = direct
      this.$emit('click', direct)
    },
    onMouseUp () {
      this.direct = ''
      this.$emit('mouseup')
    }
  }
}
</script>
<style>
.wheel-button {
  position: absolute;
  width: 98px;
  height: 46px;
  background-image: url("../../../assets/img/video/wheel-direct.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wheel-button.hold {
  background-image: url("../../../assets/img/video/wheel-direct-hold.png");
}

.rotate-90 {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}

.click-area {
  width: 82px;
  height: 30px;
  position: absolute;
  left: 50%;
  margin-left: -41px;
  cursor: pointer;
  bottom: 6px;
}
</style>
