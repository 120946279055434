<template>
  <div style="height: 100%;width: 100%;position:relative;background: #000511" v-loading="loading"
       element-loading-text="正在连接设备" element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div
      style="font-size: 12px;color: #FFF;height: 22px;line-height: 22px;position:absolute;z-index: 11;text-indent: 10px;background: rgba(1, 4, 14, 0.7);width: 100%"
      v-if="title">
      {{ title }}
    </div>

    <div style="text-align: center;padding-top: 200px" v-if="!url">
      <img src="../../../assets/img/video.png"/>
      <p style="color: #FFF;margin: 10px auto">{{ text }}</p>
    </div>

<!--    <video  :id="dynamicId" class="player" v-show="url"></video>-->
    <live-player :video-url="url" live="true" aspect="fullscreen" v-show="url" stretch="true"></live-player>
  </div>
</template>
<script>
import flv from 'flv.js'
export default {
  name: 'player',
  props: {
    info: {
      type: Object,
      default () {
        return {}
      }
    },

    url: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    },

    index: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      dynamicId: '',
      playInstance: null
    }
  },

  watch: {
    url (val) {
      console.log(val)
      if (val) {
        // this.playInstance.unload()
        // console.log(this.playInstance)
        // this.playInstance.stop()
        // this.playInstance.play(this.url, 1)
        this.playInstance = flv.createPlayer({
          type: 'flv',
          url: val
        }, {
          enableStashBuffer: false,
          autoCleanupSourceBuffer: true
        })
        const video = document.getElementById(this.dynamicId)
        this.playInstance.attachMediaElement(video)
        this.playInstance.load()
        this.playInstance.play()
      }
    }
  },

  created () {
    this.dynamicId = this.$tools.uuid()
    // if (flv.isSupported()) {
    //   console.log('========')
    //     this.playInstance = flv.createPlayer({
    //     type: 'flv',
    //     // url: 'https://rtsp.xueerqin.net/live/77744bb76fe3474290b162fc433bddf1.flv'
    //   })
    // }
  },

  mounted () {
    const _this = this
    // setTimeout(() => {
    //   this.playInstance = new window.WasmPlayer(null, this.dynamicId, function (e) {
    //     if (typeof e === 'object') {
    //       _this.playInstance.stop()
    //       _this.$emit('error', _this.info, e.code === 404 ? '播放地址不存在' : e.msg, e.code)
    //     }
    //   }, {
    //     Height: true,
    //     hasaudit: false
    //     // "http://rtsp.xueerqin.net/live/790d054e01791151c8738a8081a6001e.flv"
    //     // decodeType: 'soft'
    //   })
    //   if (this.url) this.playInstance.play(this.url, 1)
    // }, 50)
  },

  methods: {

    stop () {
      this.playInstance && this.playInstance.stop()
    }
  }
}
</script>
<style scoped>
.player{
  width: 100%;
  height:100%
}
.player-box div:nth-last-child(2) {
  height: 0;
}

.player-box .iconqingxiLOGO {
  display: none
}

/*.player-box div:last-child{*/
/*  height: 0;*/
/*}*/
</style>
